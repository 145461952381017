<template>
  <div class="homekontakt">
    <Divider />
    <div class="homekontakt__wrapper">
      <div class="homekontakt__content">
        <h1 class="h1 h1--green">Kontakt</h1>
        <div class="homekontakt__box">
          <div class="homekontakt__leftblock">
            <div class="homekontakt__item">
              <a
                :href="kontakt.googlemaps"
                target="_blank"
                class="homekontakt__a"
                >{{ kontakt.address.street }}
              </a>
            </div>
            <div class="homekontakt__item">
              <a
                :href="kontakt.googlemaps"
                target="_blank"
                class="homekontakt__a"
                >{{ kontakt.address.plz }} {{ kontakt.address.location }}
              </a>
            </div>
          </div>
          <div class="homekontakt__middleblock">
            <div class="homekontakt__divider"></div>
          </div>
          <div class="homekontakt__rightblock">
            <div class="homekontakt__item">
              <a :href="getPhone" class="homekontakt__a">{{ kontakt.phone }}</a>
            </div>
            <div class="homekontakt__item">
              <a :href="getMail" class="homekontakt__a"> {{ kontakt.email }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Divider />
  </div>
</template>

<script>
import contactInformation from "../../../utilities/siteinformation/contactInformation";
import Divider from "@/components/common/divider/Divider/";
export default {
  components: { Divider },
  setup() {
    const kontakt = contactInformation;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;
    return { kontakt, getMail, getPhone };
  },
};
</script>

<style lang="scss">
.homekontakt {
  width: 100%;
}
.homekontakt__wrapper {
  padding: 30px 0;
  width: 100%;
  @media screen and (min-width: $small) {
    padding: 60px 0;
  }
}
.homekontakt__content {
  width: 100%;
}

.homekontakt__box {
  margin-top: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: stretch;
  flex-direction: column;
  @media screen and (min-width: $medium) {
    flex-direction: row;
  }
}

.homekontakt__middleblock {
  display: none;
  position: relative;
  height: 142px;
  width: 2px;
  flex: 1 1 2px;
  @media screen and (min-width: $medium) {
    display: block;
    height: 100px;
  }
  @media screen and (min-width: $large) {
    display: block;
  }
}
.homekontakt__divider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background-color: $main-color-2;
}
.homekontakt__leftblock {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}
.homekontakt__rightblock {
  padding-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;

  @media screen and (min-width: $medium) {
    padding-top: 0px;
  }
  @media screen and (min-width: $large) {
    width: 50%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
}
.homekontakt__item {
  color: $main-color-1;
  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0.18px;
  text-transform: lowercase;
  opacity: 1;
  text-align: center;
  white-space: nowrap;
  @media screen and (min-width: $large) {
    font-size: 30px;
    line-height: 39px;
    letter-spacing: 0.18px;
  }
}
.homekontakt__a {
  display: block;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  white-space: nowrap;
}
</style>
