<template>
  <div class="gallerieone">
    <div class="gallerieone__wrapper">
      <div class="gallerieone__content">
        <div class="gallerieone__leftblock">
          <div
            class="gallerieone__item gallerieone__item--s3one gallerieone__item--big"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/3.jpg'"
              :imgposition="'center 60%'"
            />
          </div>
          <div
            class="gallerieone__item gallerieone__item--s3two gallerieone__item--hides gallerieone__item--small"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_0364.jpg'"
              :imgposition="'center 30%'"
            />
          </div>
          <div
            class="gallerieone__item gallerieone__item--s3two gallerieone__item--hidel gallerieone__item--small"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_0661.jpg'"
              :imgposition="'center 60%'"
            />
          </div>
        </div>
        <div class="gallerieone__rightblock">
          <div
            class="gallerieone__item gallerieone__item--s3three gallerieone__item--small"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_0661.jpg'"
              :imgposition="'center 80%'"
            />
          </div>
          <div
            class="gallerieone__item gallerieone__item--s3four gallerieone__item--big"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_0454.jpg'"
              :imgposition="'center 50%'"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryPic from "@/components/common/gallery/GalleryPic.vue";
import getPathFunction from "@/utilities/composition/getPathFunction";
export default {
  components: { GalleryPic },
  setup() {
    const { setBackGroundPath } = getPathFunction();
    return { setBackGroundPath };
  },
};
</script>

<style lang="scss">
.gallerieone__item {
  &--s3one {
    width: 100%;
    flex: 1 1 auto;
  }
  &--s3two {
    width: 100%;
    flex: 0 1 auto;
  }
  &--s3three {
    width: 100%;
    flex: 0 1 auto;
  }
  &--s3four {
    width: 100%;
    flex: 1 1 auto;
  }
}
</style>
