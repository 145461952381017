<template>
  <div class="homegalleryinfo">
    <div class="homegalleryinfo__wrapper">
      <h1 class="homegalleryinfo__h1 h1 h1--white">Eat Pray Drink</h1>
      <div class="homegalleryinfo__boxbutton">
        <div class="homegalleryinfo__btnbox">
          <AppButton
            class="introb1__btn"
            :link="'/assets/karte/thi_bistrokarte.pdf'"
            >Bistro</AppButton
          >
        </div>
        <div class="homegalleryinfo__btnbox">
          <AppButton
            class="introb1__btn"
            :link="'/assets/karte/thi_getaenkekarte.pdf'"
            >Getränke</AppButton
          >
        </div>
        <div class="homegalleryinfo__btnbox">
          <AppButton
            class="introb1__btn"
            :link="'/assets/karte/thi_speisekarte.pdf'"
            >Speisekarte</AppButton
          >
        </div>
        <div class="homegalleryinfo__btnbox">
          <AppButton
            class="introb1__btn"
            :link="'/assets/karte/thi_weinkarte.pdf'"
            >Wein</AppButton
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppButton from "../../ui/buttons/AppButton.vue";
export default {
  components: { AppButton },
};
</script>

<style lang="scss">
$piblock: 60px;

.homegalleryinfo {
  margin: 0 auto;
  //   margin-top: -200px;
  background-color: $main-color-1;
  position: relative;
}
.homegalleryinfo__wrapper {
  padding-top: 54px;
  padding-left: $main-padding-section-s;
  padding-right: $main-padding-section-s;

  padding-bottom: 54px;
  background-color: $main-color-1;

  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
    padding-top: $main-padding-section;
    padding-left: $main-padding-section;
    padding-right: $main-padding-section;

    padding-bottom: $main-padding-section;
  }
  @media screen and (min-width: $xxlarge) {
  }
}

.homegalleryinfo__boxbutton {
  width: 100%;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 4px;
}

.homegalleryinfo__btn {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;

  margin-top: 15px;
}
.homegalleryinfo__btnbox {
  display: flex;
  justify-content: center;
}
</style>
