<template>
  <div class="galleriesec">
    <div class="galleriesec__wrapper">
      <h1 class="introb1__h1 h1 h1--green">
        Wir sagen Ja zu jeder Gelegenheit!
      </h1>
      <div class="galleriesec__content">
        <div class="galleriesec__leftblock">
          <div
            class="galleriesec__item galleriesec__item--one"
            data-motion="'hallo'"
          >
            <GalleryPic
              :imgpath="'assets/img/00_startseite/21_06_07_THI_0862.jpg'"
            />
          </div>
        </div>
        <div class="galleriesec__rightblock">
          <div
            class="galleriesec__item galleriesec__item--two"
            data-motion="'hallo'"
          >
            <div class="galleriesec__itemwrapper">
              <h1 class="introb1__h1 h1 h1--white">
                Bonjour
              </h1>
              <p class="p p--white introb1__p">
                Herzlich willkommen in der Brasserie THI! Wir stehen für
                französische Leidenschaft, die mit asiatischer Perfektion
                abgeschmeckt wird. Genießen Sie neben feinsten Gerichten und
                ausgewählten Weinen ein modernes französisches Flair inklusive
                einer großen Portion Savoir-vivre. Wir freuen uns auf Sie!
              </p>
              <div class="introb1__boxbutton">
                <router-link :to="prefixPath('ueber')" class="introb1__a">
                  <AppButton class="introb1__btn">Über uns</AppButton>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GalleryPic from "@/components/common/gallery/GalleryPic.vue";
import getPathFunction from "@/utilities/composition/getPathFunction";
import AppButton from "../../ui/buttons/AppButton.vue";
export default {
  components: { GalleryPic, AppButton },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const publicPath = process.env.BASE_URL;
    function prefixPath(el) {
      return publicPath + el;
    }

    return { setBackGroundPath, prefixPath };
  },
};
</script>

<style lang="scss">
.galleriesec {
  position: relative;
  width: 100%;
  display: flex;
}
.galleriesec__wrapper {
  width: 100%;
}
.galleriesec__content {
  display: flex;
  flex-direction: column;
  justify-items: stretch;
  @media screen and (min-width: $medium) {
    flex-direction: row;
  }
}

.galleriesec__leftblock {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $medium) {
    width: 50%;
  }
  @media screen and (min-width: $large) {
    width: 50%;
  }
}
.galleriesec__rightblock {
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $medium) {
    width: 50%;
  }
  @media screen and (min-width: $large) {
    width: 50%;
  }
}

.galleriesec__item {
  position: relative;
  overflow: hidden;
  &--one {
    width: 100%;
    // 0.773
    height: 411px;
    flex: 1 1 auto;

    @media screen and (min-width: $medium) {
      width: 100%;
      // 0.773
      height: 200px;
      flex: 1 1 auto;
    }
  }
  &--two {
    padding: 20px 0;

    width: 100%;
    flex: 1 1 auto;
  }
}

.galleriesec__itemwrapper {
  width: 100%;

  position: relative;
  padding-top: 54px;
  padding-left: $main-padding-section-s;
  padding-right: $main-padding-section-s;
  padding-bottom: 54px;
  background-color: $main-color-1;

  @media screen and (min-width: $medium) {
  }
  @media screen and (min-width: $large) {
  }
  @media screen and (min-width: $xlarge) {
    padding: $main-padding-section 60px;
  }
  @media screen and (min-width: $xxlarge) {
  }
}
</style>
